<template>
  <div class="container line_jp">
    <van-nav-bar title="组合套餐" :fixed="true">
      <template #left>
        <van-icon :size="24" @click="$router.go(-1)" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="top_banner">
      <div class="banner_out p_r">
        <div class="swiper-wrapper">
          <div class="swiper-slide p_r" v-for="(item,index) in bannerArr" :key="index" @click="banner1Click(item,null,'1')">
            <van-image fit="cover" :src="item.imgUrl"></van-image>
            <div class="p_a d_f ali_c f_w j_s">
              <span>{{item.description}}</span>
              <!--<span class="pri">￥<span class="num">1212</span></span>-->
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>

      </div>
    </div>
    <div class="all_line">
      <div class="al_title">全部线路</div>
      <div class="al_list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            :immediate-check="false"
            v-model="isLoading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadBottom"
          >
            <div class="p_r card_out" v-for="(item,index) in ComposeCommodity" :key="index">
              <div class="d_f ali_c j_s p_r">
                <div class="card_swiper">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <van-image fit="cover" :src="item.cover"></van-image>
                      <div class="s_title text_over">{{item.name}}</div>
                      <div class="s_tag_out" v-for="(itm,idx) in item.tags.split(',')" :key="idx">
                        <div class="d_i">{{itm}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="co_ri">
                  <div class="cr_title">
                    {{item.name}}
                  </div>
                  <div class="cr_bor">
                    <div class="crb_ti">-<span>套餐说明</span>-</div>
                    <div class="crb_detail">{{item.remark}}</div>
                  </div>
                </div>
              </div>
              <div class="d_f ali_c co_b_out">
                <span class="pri">￥<span class="num">{{item.sellPrice ? item.sellPrice/100 : 0}}</span></span>
                <span class="sub_pri flex_1">￥<span>{{item.shopPrice ? item.shopPrice/100 : 0}}</span></span>
                <div class="co_del_btn cur_p d_f ali_c j_c" @click="$router.push({ path:'/Combo',query:{commodityId:item.commodityId,sellerId:item.sellerId}})">
                  <span>套餐详情</span>
                  <img src="../../assets/img/h_zh_ri.png" alt="">
                </div>
              </div>

            </div>
          </van-list>
        </van-pull-refresh>

      </div>
    </div>
  </div>
</template>

<script>
  import { NavBar, Icon, Toast, Dialog,Image, PullRefresh, List } from 'vant'
  import '../../../public/swiper/swiper.min.css';
  import  Swiper from '../../../public/swiper/swiper.min.js';
  import  api  from '../../api/index'
  export default {
    name: "line",
    components:{
      'van-image':Image,
      'van-icon':Icon,
      'van-nav-bar':NavBar,
      'van-pull-refresh': PullRefresh,
      'van-list': List,
    },
    data() {
      return {

        refreshing: false, //是否刷新
        isLoading:false,
        finished:true,
        ComposeCommodity:[],
        bannerArr:[],
      }
    },
    created(){
      this.getList()
      this.queryBanner()
    },
    methods: {

      // 点击顶部banner
      banner1Click(row,type,isOut,zh){
        // xyhwcldnblj 内部跳转的remark匹配这个
        let url = row.outurl || row.interlinkage
        if(url){
          let out = false;
          if(type) out = true;
          if(isOut){
            if(row.remark.indexOf("xyhwcldnblj") != -1){
              out = false
            }else{
              out = true
            }
          }
          if(out){
            if(!zh){
              if(url.indexOf("token") != -1){
                let url = window.location.href;
                // console.log(row.interlinkage+this.$global.token+'&backUrl='+window.location.href)
                window.location.href = url+this.$global.token+'&backUrl='+url;
              }else{
                window.location.href = url
              }
            }else{
              this.$router.push({
                path:'/outWindow',
                query:{
                  interlinkage:encodeURI(url)
                }
              })

            }
          }else{
            this.$router.push(row.interlinkage.split('#')[1])
          }
        }
      },
      //banner
      queryBanner(){
        api.queryBanner({position:18}).then(res => {
          let data = JSON.parse(JSON.stringify(res.data));
          this.bannerArr = data;
          this.$nextTick(() => {
            this.bannerSwiper()
          });
        })
      },
      getList(){

        let _t = this;
        api.queryComposeCommodity().then(res => {
          _t.ComposeCommodity = res.data;
        })
      },
      //上啦加载
      onLoadBottom(){
        let me = this;
        this.pageIndex = this.pageIndex ? this.pageIndex + 1 : 1;
        me.getList();
      },
      //下拉刷新
      onRefresh(){
        this.ComposeCommodity = [];
        this.finished = false;
        this.pageIndex = 1;
        this.getList();
      },
      bannerSwiper(){
        let bannerSwiper = new Swiper('.banner_out',{
          // loop: true, // 循环模式选项
          spaceBetween: 50,
          speed: 500, //切换时长
          // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
          autoplay: {
            delay: 3000,   //自动切换延时
            stopOnLastSlide: false, //如果设置为true，当切换到最后一个slide时停止自动切换。（loop模式下无效）。
            disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
          },
          keyboard: true,  //键盘切换
          pagination: {
            el: '.swiper-pagination',
          },
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .all_line{
    .al_list{
      .card_out{
        .co_b_out{
          position: relative;
          top: -12px;
          .co_del_btn{
            img{
              margin-left: 9px;
              width: 8px;
              height: 14px;
            }
            width: 88px;
            height: 36px;
            background: linear-gradient(90deg, #FF8E5A, #FB5C5F);
            border-radius: 18px;
            font-size: 12px;
            font-weight: bold;
            color: #FFFFFF;
          }
          .sub_pri{
            font-size: 12px;
            font-weight: bold;
            color: #A2A2A2;
            text-decoration: line-through;
          }
          .pri{
            .num{
              font-size: 18px;
            }
            font-size: 10px;
            font-weight: bold;
            color: #F96937;
            margin-right: 10px;
          }
          width: 100%;
        }
        >.p_r{
          top: -40px;
        }
        .co_ri{
          position: relative;
          top: 17px;
          .cr_bor{
            .crb_detail{
              font-size: 10px;
              font-weight: 400;
              color: #8C8989;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .crb_ti{
              text-align: center;
              font-size: 12px;
              font-weight: bold;
              font-style: italic;
              color: #F96937;
              margin-bottom: 6px;
            }
            padding: 4px 6px 10px;
            box-sizing: border-box;
            width: 171px;
            background: #FFFCF3;
            border: 1px solid #F96937;
            border-radius: 10px;
          }
          .cr_title{
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            min-height: 31px;
            margin-bottom: 11px;
          }
        }
        .card_swiper{
          width: 131px;
          margin-right: 30px;
          .swiper-slide{
            .s_tag_out{
              >div{
                padding: 3px 4px;
                background-color: rgba(255,255,255,0.2);
                margin-right: 2px;
              }
              font-size: 10px;
              font-weight: 400;
              color: #FFFFFF;
            }
            .s_title{
              margin-bottom: 26px;
              font-size: 14px;
              font-weight: bold;
              color: #FFFFFF;
            }
            .van-image{
              width: 100%;
              height: 72px;
              border-radius: 10px 10px 0 0;
              overflow: hidden;
              margin-bottom: 8px;
            }
            width: 131px;
            border-radius: 10px;
            padding: 6px 6px 10px;
            box-sizing: border-box;
            background-color: #7E97B5;
          }
        }

        &:nth-child(2n){
          .swiper-slide{
            background-color: #24538A;
          }
        }
        &:nth-child(3n){
          .swiper-slide{
            background-color: #299D6D;
          }
        }
        padding: 16px 12px 0 9px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 2px 2px 0 rgba(6, 0, 1, 0.1);
        border-radius: 5px;
        margin-top: 50px;
        box-sizing: border-box;
        margin-bottom: 16px;
      }
    }
    .al_title{
      font-size: 21px;
      font-weight: bold;
      color: #141212;
    }
    padding: 16px 13px;
    background-color: #F0F0F0;
  }
  .top_banner{
    .swiper-pagination{
      bottom: -20px;
    }
    .swiper-slide{
      .p_a{
        .pri{
          .num{
            font-size: 18px;
          }
          font-size: 10px;
          font-weight: bold;
          color: #F96937;
        }
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        box-sizing: border-box;
        padding: 0 28px;
        height: 40px;
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
      }
      width: 100%;
      height: 190px;
    }
    .banner_out {
      width: 100%;
      height: 100%;
      /*overflow: hidden;*/
    }
    padding: 20px 20px 24px;
    background-color: white;
  }
  .container{
    padding-top: 45px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
